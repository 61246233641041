













































































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import { DisplayReport, RowData } from './types';
import moment from 'moment';
import axios from 'axios';
const excel = require('../libs/excel');
const _ = require('lodash');

interface RowDataReport extends RowData {
  time?: string;
}
export default Vue.extend({
  components: {
    DatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    const date = moment().valueOf();
    return {
      display: false,
      reportTypes: [
        { id: 'daily', txt: '日報' },
        { id: 'monthly', txt: '月報' },
      ],
      columns: Array<any>(),
      statRows: Array<any>(),
      rows: Array<RowDataReport>(),
      form: {
        date,
        reportType: 'daily',
      },
    };
  },
  computed: {
    pickerType() {
      if (this.form.reportType === 'daily') return 'date';
      return 'month';
    },
  },
  methods: {
    async query() {
      this.display = true;
      const url = `/monitorReport/${this.form.reportType}/${this.form.date}`;
      const res = await axios.get(url);
      this.handleReport(res.data);
    },
    downloadReport() {
      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:9000/' : '/';

      const url = `${baseUrl}Excel/monitorReport/${this.form.reportType}/${this.form.date}`;

      window.open(url);
    },
    handleReport(report: DisplayReport) {
      this.columns.splice(0, this.columns.length);
      if (this.form.reportType === 'daily') {
        this.columns.push({
          key: 'time',
          label: '時間',
          sortable: true,
        });
      } else {
        this.columns.push({
          key: 'time',
          label: '日期',
          sortable: true,
        });
      }
      for (let i = 0; i < report.columnNames.length; i++) {
        this.columns.push({
          key: `cellData[${i}].v`,
          label: `${report.columnNames[i]}`,
          tdClass: this.cellDataTd(i),
          sortable: true,
        });
      }
      for (const r of report.rows) {
        const row = r as RowDataReport;
        row.time =
          this.form.reportType === 'daily'
            ? moment(row.date).format('HH:mm')
            : moment(row.date).format('MM/DD');
      }
      this.rows = report.rows;
      this.statRows = report.statRows;
    },
    cellDataTd(i: number) {
      return (_value: any, _key: any, item: any) =>
        item.cellData[i].cellClassName;
    },
    exportExcel() {
      const title = this.columns.map(e => e.label);
      const key = this.columns.map(e => e.key);
      for (let entry of this.rows) {
        let e = entry as any;
        for (let k of key) {
          e[k] = _.get(entry, k);
        }
      }
      let filename =
        this.form.reportType === 'daily'
          ? `${moment(this.form.date).format('ll')}日報`
          : `${moment(this.form.date).year()}年${
              moment(this.form.date).month() + 1
            }月報`;

      const params = {
        title,
        key,
        data: this.rows,
        autoWidth: true,
        filename,
      };
      excel.export_array_to_excel(params);
    },
  },
});
